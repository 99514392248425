<template>
  <div>
    <h3>SSL Certificate Manager</h3>
    <v-btn
      text
      :to="{ name: 'manage-websites', params: { domainname: siteUrl }}"
      outlined
      class="btn btn-lg btn-secondary-main"
      style="background:transparent;"
    >
      <i class="fas fa-arrow-left"></i> Back
    </v-btn>
    <span class="btn btn-label-primary btn-sm btn-bold kt-margin-r-10 pull-right">
      <i class="fa fa-globe"></i>
      {{siteUrl}}
    </span>
    <v-app>
      <div class="row mt-2" >
        <div class="col-xl-12">
          <KTPortlet v-bind:title="''">
            <template v-slot:body>
              <v-row v-if="website.domain_name !=null || website.lifetime_membership || website.is_custom_domain">
                <v-col cols="8" class="p-15">
                  <span class="badge badge-info">{{siteUrl}}</span>
                  <hr />
                  <h5>Add SSL certificate to your domain</h5>
                  <br />
                  <h6>Benefits</h6>
                  <ul class="ml-10">
                    <li>Increased security</li>
                    <li>Secure Payment System</li>
                    <li>Rank better on search engine</li>
                  </ul>


                  <v-row v-if="!website.has_ssl_certificate">
                   <div v-if="website.www_record.length>0">
                     <h6>
                       We need few details from you to generate certificate for your website. Please fill in the form below
                       to continue activating SSL security on your website.
                     </h6>
                     <v-col cols="6">
                       <v-text-field
                           v-model="website.ssl_country"
                           label="Country"
                           value="NP"
                           disabled
                           required
                           outlined
                           densed
                       ></v-text-field>
                       <p
                           class="text-danger font-weight-bold"
                           v-if="$v.website.ssl_country.$error"
                       >Country is required</p>
                       <v-text-field
                           v-model="website.ssl_state"
                           label="Your current state"
                           required
                           outlined
                           densed
                       ></v-text-field>
                       <p
                           class="text-danger font-weight-bold"
                           v-if="$v.website.ssl_state.$error"
                       >State is required</p>
                       <v-text-field
                           v-model="website.ssl_city"
                           label="Your current city"
                           required
                           outlined
                           densed
                       ></v-text-field>
                       <p
                           class="text-danger font-weight-bold"
                           v-if="$v.website.ssl_city.$error"
                       >City is required</p>
                     </v-col>
                     <v-col cols="6">
                       <v-text-field
                           v-model="website.ssl_organisation"
                           label="Your personal/business name "
                           required
                           outlined
                           densed
                       ></v-text-field>
                       <p
                           class="text-danger font-weight-bold"
                           v-if="$v.website.ssl_organisation.$error"
                       >Personal name or business name is required</p>
                       <v-text-field
                           v-model="website.ssl_organisation_unit"
                           label="Tech Team"
                           value="Technical Team"
                           required
                           outlined
                           densed
                       ></v-text-field>

                       <p
                           class="text-danger font-weight-bold"
                           v-if="$v.website.ssl_organisation_unit.$error"
                       >Technical team is required</p>
                     </v-col>

                     <v-btn
                         @click="saveSSLSetting"
                         color="primary"
                     >Install & Activate SSL Certificate for free</v-btn>
                   </div>

                  <div v-else>
                    <hr>
                    <h6 class="text-warning">DNS configuration incomplete</h6>
                    <p>We cannot found a CNAME for "www" record for this domain. Please add a "www" record.</p>
                  </div>
                  </v-row>

                  <v-row v-if="website.has_ssl_certificate">
                    <v-col>
                      <h6>
                        <i class="fa fa-check-circle text-success"></i> Your website is secured. We will renew your certificate as long as you have a valid subscription plan with us.
                      </h6>


                      <v-stepper alt-labels v-if="website.ssl">
                        <v-stepper-header>
                          <v-stepper-step step="1"  complete>Generating Certificate</v-stepper-step>

                          <v-divider></v-divider>

                          <v-stepper-step step="2" :complete="website.ssl && website.ssl.status=='draft' || website.ssl.status=='issued' || website.ssl.status=='complete'">Verifying Certificate</v-stepper-step>

                          <v-divider></v-divider>

                          <v-stepper-step step="3" :complete="website.ssl && website.ssl.status=='issued' || website.ssl.status=='complete'">Installing Certificate</v-stepper-step>

                          <v-divider></v-divider>

                          <v-divider></v-divider>

                          <v-stepper-step step="4" :complete="website.ssl && website.ssl.status=='complete'">Complete</v-stepper-step>
                        </v-stepper-header>
                      </v-stepper>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="4" class="text-center" v-if="website.ssl && website.ssl.created=='0000-00-00 00:00:00'">
                  <img src="/assets/media/icons/ssl-encryption-icon.png" />
                  <br />Certificate not issued
                </v-col>

                <v-col cols="4" class="text-center" v-if="website.ssl && website.ssl.created!='0000-00-00 00:00:00'">
                  <img src="/assets/media/icons/ssl-encryption-icon.png" />
                  <br />
                  Certificate Issued on : {{website.ssl_issued_date}}
                  <br />
                  Certificate Expires on : {{website.ssl_expiry_date}}
                </v-col>
              </v-row>
              <v-row v-else>
                  <v-col cols="12" align="center">
                      <img width="200" all position="center" class="img-fluid"  contain src="/assets/media/illustrations/undraw_secure_login_pdn4.svg" />
                  </v-col>

                  <v-col cols="12" align="center">
                      <h2>SSL Certificate Activated </h2>
                      <p>Your site is secured with SSL. All connections made to your website are encrypted.</p>
                  </v-col>
              </v-row>
              <small class="text-warning text-h6 mt-2">SSL certificate process may take time up to 30 mins.</small>

            </template>
          </KTPortlet>
        </div>
      </div>
    </v-app>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/store/breadcrumbs.module";
import KTPortlet from "@/views/partials/content/Portlet.vue";
import ApiService from "@/common/api.service";
import { required } from "vuelidate/lib/validators";
import Website from "@/services/Websites/WebsiteService";
const WebsiteService = new Website();
export default {
  name: "Manage-ssl",
  components: {
    KTPortlet
  },
  validations: {
    website: {
      ssl_country: { required },
      ssl_state: { required },
      ssl_city: { required },
      ssl_organisation: { required },
      ssl_organisation_unit: { required }
    }
  },
  data() {
    return {
      website: {
        domain_name: null,
        sub_domain_name: null,
        user_id: null,
        template_id: null,
        has_dns: 0,
        has_ssl: 0,
        ssl_country: null,
        ssl_state: null,
        ssl_city: null,
        ssl_organisation: null,
        ssl_organisation_unit: null,
        is_custom_domain: null,
        plan_id: null,
        plan_expiry_date: null,
        lifetime_membership: null,
        is_linked_organisation: null,
        is_active: 0
      }
    };
  },
  methods: {
    showDialog() {
      this.socialShareDiaolog = true;
    },
    closeDialog() {
      this.socialShareDiaolog = false;
    },
    toggleBusy() {
      this.isBusy = !this.isBusy;
    },
    imageChange(e) {
      this.url = URL.createObjectURL(e);
    },

    getWebsiteDetail() {
      WebsiteService.show(this.siteUrl).then(res => {
        this.website = res.data.website;
        this.website.ssl_country = "NP";
      });
    },
    saveSSLSetting() {
      this.$v.$touch();
      if (!this.$v.website.$error) {
        WebsiteService.activateSSL(this.siteUrl, this.website).then(res => {
          this.getWebsiteDetail();
          this.$snotify.success("SSL activation in progress");
        });
      }
    }
  },
  computed: {

    siteUrl() {
      return this.$route.params.domainname;
    }
  },
  mounted() {
    this.getWebsiteDetail();
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Manage SSL", route: "/websites" }
    ]);
  }
};
</script>
